import { mutationsType } from "./mutations-type";
import initialValue from "./initialValue";

export default {
  [mutationsType.SHOW_LOADING](state) {
    state.loading = true;
  },
  [mutationsType.HIDE_LOADING](state) {
    state.loading = false;
  },
  [mutationsType.SET_LOGIN_PRE_PATH](state, prePath) {
    state.loginPrePath = prePath;
  },
  [mutationsType.INIT_ACCOUNT_INFO](state) {
    state.accountInfo = initialValue.accountInfo;
  },
  [mutationsType.SET_ACCOUNT_INFO](state, accountInfo) {
    state.accountInfo = accountInfo;
  }
};
