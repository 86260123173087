module.exports = {
    "common":{
        "popup":"メッセージ",
        "ok":"OK",
        "cancel":"取消"
    },
    "login":{
        "username":"アカウント",
        "password":"パスワード",
        "slogan":"プレシャスパートナーズは\"雇用のミスマッチをなくす\"というミッションを掲げ、2008年に設立した《総合人材サービス企業》です。東京、名古屋、大阪、福岡を拠点とし、人材採用に関する様々なサービスを展開、採用の総合コンサルティングを行っています。2019年には就活イベントの運営やHRテックサービスの取り扱いも開始。人財は経営資源の中で最も重要な位置付けにあるという考えに基づき、採用の先を見据えて課題解決に取り組んでまいりました。2020年にはテイクアウトで飲食店を\"応援\"するサイト「ハピテク」をリリース。採用だけにとどまらない支援サービスを展開しています。わたしたちは、みなさまのかけがえのないパートナーとして、これからも挑戦と成長を続けてまいります。",
        "login":"ログイン",
        "forgetPwd":"パスワードレセット",
        "err1":"ユーザー名を入力してください。",
        "err2":"パスワードを入力してください。"
    },
    "account":{
        "account":"アカウント",
        "info":"アカウント情報",
        "contactor":"名前",
        "mobile":"携帯",
        "mail1":"メール",
        "systemmail":"システム通知",
        "billmail":"請求書",
        "update":"更新",
        "cancel":"取消",
        "err1":"名前を入力してください。",
        "err2":"1桁から20桁の内容を入力してください。",
        "err3":"携帯番号を入力してください。",
        "err4":"11桁の携帯番号を入力してください。",
        "err5":"メールアドレスを入力してください。",
        "err6":"正しいメールアドレスを入力してください。"
    },
    "forgetPWD":{
        "mail":"メール",
        "reset":"パスワードリセット",
        "back":"戻る",
        "err1":"メールアドレスを入力してください。",
        "suc1":"パスワードリセットは成功しました。結果についてメールで確認してください。"
    },
    "resetPWD":{
        "account":"アカウント",
        "udpatePWD":"パスワード更新",
        "oldPassword":"パスワード",
        "newPassword":"新パスワード",
        "newPassword2":"確認パスワード",
        "update":"更新",
        "err1":"パスワードが一致していません。",
        "err2":"パスワードを入力してください。",
        "err3":"8桁から20桁の内容を入力してください。",
        "err4":"新パスワードを入力してください。",
        "err5":"確認パスワードを入力してください。"
    },
    "addGroup":{
        "admin":"管理",
        "addRole":"グループ新規",
        "role":"グループ情報",
        "name":"グループ名",
        "process":"プロセス",
        "add":"新規",
        "back":"戻る"
    },
    "addUser":{
        "admin":"管理",
        "addUser":"ユーザー新規",
        "account":"ユーザー情報",
        "loginName":"アカウント",
        "name":"ユーザー名",
        "contactor":"ユーザー名",
        "password":"パスワード",
        "A":"システム管理員",
        "B":"普通社員",
        "C":"代理店社員",
        "T":"技術",
        "role":"ロール",
        "group":"グループ",
        "mobile":"携帯",
        "mail1":"メール",
        "systemmail":"システム通知",
        "billmail":"請求書",
        "notice":"通知",
        "notnotice":"通知しない",
        "create":"新規",
        "back":"戻る",
        "err1":"ユーザー名を入力してください。",
        "err2":"1桁から20桁の内容を入力してください。",
        "err3":"携帯番号を入力してください。",
        "err4":"11桁の携帯番号を入力してください。",
        "err5":"メールを入力してください。",
        "err6":"正しいメールアドレスを入力してください。",
        "err7":"パスワードを入力してください。",
        "err8":"正しいパスワードを入力してください。"
    },
    "listGroup":{
        "group":"件",
        "add":"新規",
        "code":"コード",
        "name":"グループ名",
        "bindprocess":"プロセス",
        "process":"処理",
        "remove":"削除",
        "confirm":"該当ロールを削除してよろしいでしょうか？",
        "popup":"メッセージ",
        "ok":"OK",
        "cancel":"取消"
    },
    "listUser":{
        "user":"件",
        "add":"新規",
        "loginName":"アカウント",
        "name":"ユーザー名",
        "role":"ロール",
        "group":"グループ",
        "process":"処理",
        "edit":"修正",
        "remove":"削除",
        "resetPwd":"該当ユーザーのパスワードを再発行します。よろしいでしょうか？",
        "confirm":"該当ユーザーを削除してよろしいでしょうか？",
        "popup":"メッセージ",
        "ok":"OK",
        "cancel":"取消"
    },
    "updateUser":{
        "manage":"管理",
        "updateUser":"ユーザー情報更新",
        "update":"更新",
        "err1":"ユーザー名を入力してください。",
        "err2":"1桁から20桁の内容を入力してください。",
        "err3":"携帯番号を入力してください。",
        "err4":"11桁の携帯番号を入力してください。",
        "err5":"メールを入力してください。",
        "err6":"正しいメールアドレスを入力してください。",
        "err7":"パスワードを入力してください。",
        "err8":"正しいパスワードを入力してください。"
    },
    "payReport":{
        "totalCount":"件",
        "month":"月",
        "functionName":"機能",
        "currentPlan":"セット",
        "extraFee":"セット外費用",
        "totalFee":"アカウント変更",
        "balance":"アカウント残額",
        "date":"処理時間"
    },
    "processReview":{
        "auto":"自動",
        "manual":"手動",
        "command":"実行",
        "resource":"リソース",
        "back":"返回"
    },
    "process":{
        "my":"マイプロセス",
        "start":"起動...",
        "stop":"終了...",
        "confirm1a":"プロセス",
        "confirm1b":"をオンラインにしてよろしいでしょうか。",
        "confirm2a":"プロセス",
        "confirm2b":"をオフラインにしてよろしいでしょうか。"
    },
    "monitor":{
        "input":"ファイル",
        "robotName":"ロボット",
        "functionName":"機能",
        "date":"日付",
        "start":"開始",
        "end":"終了",
        "result":"結果",
        "process":"処理",
        "redo":"再起動",
        "unit":"件"
    },
    "myrobot":{
        "confirm1a":"ロボット",
        "confirm1b":"を起動してよろしいでしょうか",
        "confirm2a":"ロボット",
        "confirm2b":"を停止してよろしいでしょうか",
        "confirm3a":"ロボット",
        "confirm3b":"を更新してよろしいでしょうか",
        "start":"起動...",
        "stop":"終了...",
    },
    "runningReport":{
        "graph":"グラフ",
        "calling":"実行回数",
        "durationAvg":"平均実行時間（秒）",
        "persent":"成功率",
        "newMonth":"期間",
        "month":"対象月",
        "date":"日付",
        "robotName":"ロボット",
        "functionName":"機能",
        "callSum":"実行回数",
        "callSuccess":"成功回数",
        "callFail":"失敗回数",
        "duration":"実行時間（秒）",
        "list":"一覧",
        "unit":"件"
    },
    "planList":{
        "set":"セット情報",
        "function":"機能",
        "plan":"名前",
        "start":"有効期間開始",
        "end":"有効期間終了",
        "amount":"残回数",
        "balance":"残額"
    },
    "menu":{
        "process":"プロセス",
        "robot":"ロボット",
        "cloud":"クラウド",
        "report":"レポート",
        "bill":"費用",
        "admin":"管理",
        "user":"ユーザー",
        "group":"グループ",
        "account":"アカウント",
        "password":"パスワード修正",
        "logout":"ログアウト"
    },
    "processItem":{
        "online":"オンライン",
        "offline":"オフライン",
        "workday":"稼働日",
        "day":"毎日",
        "detail":"詳細",
        "ver":"版"
    },
    "robotItem":{
        "online":"起動",
        "offline":"終了",
        "config":"設定",
        "update":"更新",
        "ver":"版"
    },
    "robotConfig":{
        "config":"-設定",
        "exe":"実行方法：",
        "by":"頻度",
        "bytime":"定時",
        "time":"間隔時間：",
        "unit":"単位",
        "min":"分",
        "hour":"時間",
        "day":"毎日：",
        "selecttime":"対象時間",
        "weekend":"週末込み",
        "hint":"再起動してください。"
    },
    "report":{
        "function":"機能",
        "byfunction":"機能実行回数集計",
        "byrobot":"ロボット実行回数集計",
        "bymonth":"当月実行回数集計",
    },
    "search":{
        "search":"検索",
        "reset":"リセット",
        "selMonth":"月",
        "selDuration":"期間",
        "selFunction":"機能",
        "today":"当日",
        "week":"当週",
        "month":"当月",
        "selStatus":"状態",
        "success":"成功",
        "fail":"失敗",
        "running":"実行中",
        "selRobot":"ロボット",
        "file":"ファイル",
        //TASK
        "created":"アサインなし",
        "signed":"アサイン済",
        "saved":"保存",
        "completed":"完成",
        "deleted":"削除",
        "keyword":"キーワード",
        "out":"出荷フォーム",
        "compare":"比較",
        "customer":"税関申込",
        "OrgPic": "原図"
    },
    "task": {
        "view": "見る",
        "type":"種別",
        "keyword":"キーワード",
        "createtime":"新規時間",
        "assigntime":"アサイン時間",
        "endtime": "完了時間",
        "interval": "間隔",
        "assigner":"アサイン者",
        "status":"状態",
        "process":"操作",
        "assign":"アサイン",
        "delete":"削除",
        "truncate":"クリア",
        "rollback":"回復",
        "file":"ファイル",
        "robot":"ロボット",
        "date":"日付",
        "start":"開始時間",
        "end":"終了時間",
        "info":"メッセージ",
        "compare":"比較",
        "redo":"再実行"
    },
    "taskDetail": {
        "upload": "アップロード", 
        "repeatCheck": "重複チェック", 
        "print": "印刷",
        "save":"保存",
        "submit":"サブミット",
        "delete":"削除",
        "back":"戻る",
        "addRow":"データ追加",
        "openOrgPic":"原図",
        "recSeal":"印章識別",
        "storeCmt":"倉庫備考",
        "autoPick":"自動ピック"
    },
    "listCustomer":{
        "count":"件",
        "add":"新規",
        "nameKj":"顧客名（漢）",
        "applyDate":"申込日",
        "printKbn":"印刷区分",
        "newDay":"新着曜日",
        "occupation":"職種",
        "visitDate":"訪問日時",
        "status":"処理ステータス",
        "createUser":"登録ユーザー",
        "createTime":"登録時間",
        "ok":"OK",
        "cancel":"取消",
        "process":"処理",
    },
    "addCustomer":{
        "title": "案件登録",
        "title2": "案件修正",
        "registry": "登記簿情報",
        "corporateName": "法人名",
        "representative": "代表者名",
        "customer": "顧客",
        "customerId": "顧客ID",
        "search": "検索",
        "add": "新規",
        "delete": "削除",
        "confirm": "確認",
        "back": "キャンセル",
        "locationName": "拠点名",
        "name": "顧客名",
        "comp": "COMP",
        "zipCode": "郵便番号",
        "prefecture": "都道府県",
        "address1": "住所1",
        "address2": "住所2",
        "tel": "TEL",
        "fax": "FAX",
        "person1": "担当者1",
        "person2": "担当者2",
        "post": "役職",
        "comment": "コメント",
        "managementId":"管理画面ID",
        "managementPw": "管理画面PWD",
        "managementName": "管理画面名",
        "questionnaire": "アンケート",
        "destinationAddress": "メアド",
        "send": "送信する",
        "memo": "備考",
        "payee": "請求先",
        "payeeId": "請求先ID",
        "sameInformation": "顧客情報と同様",
        "payeeNm": "請求先名",
        "deAddress": "送付先住所",
        "buildName": "ビル名",
        "address3": "お宛名",
        "transferFee": "振込手数料",
        "payment": "支払方法",
        "transferName": "振込名",
        "contract": "契約内容",
        "applyDay": "申込日",
        "printKbn": "印刷区分",
        "newDay": "新着曜日",
        "job": "職種",
        "visitTime": "訪問日時",
        "section1": "部署1",
        "section2": "部署2",
        "business": "営業担当者",
        "profitSharing": "利益分配率",
        "service": "サービス",
        "recruiting": "募集先情報",
        "requiredInfo": "申込時必要情報",
        "saas": "SAAS",
        "cloudSign": "クラウドサイン情報",
        "dipSysInfo": "DIPフロントシステム情報",
    }
};