import Vue from "vue";
import VueRouter from "vue-router";
import { ROUTER_PATH } from "@/constants/common.js";

Vue.use(VueRouter);

const routes = [
  {
    path: ROUTER_PATH.LOGIN,
    name: "login",
    component: () => import("../views/account/Login.vue"),
    meta: { title: "ログイン", needLogin: false }
  },
  {
    path: "/",
    name: "layout",
    component: () => import("../views/layout"),
    redirect:ROUTER_PATH.APPLY_CENTER,
    children: [
      {
        path: ROUTER_PATH.LOGIN_OUT,
        name: "accountLoginOut",
        component: () => import("../views/account/LoginOut.vue"),
        meta: { title: "ログアウト", needLogin: false }
      },
      {
        path: "/ac",
        name: "ApplyCenter",
        component: () => import("../views/pp/ApplyCenter.vue"),
        meta: { title: "案件一覧", needLogin: true }
      },
      {
        path: ROUTER_PATH.ADD_CUSTOMER,
        name: "addCustomer",
        component: () => import("../views/pp/AddCustomer.vue"),
        meta: { title: "案件登録", needLogin: true }
      },
      {
        path: ROUTER_PATH.ADMIN_USER,
        name: "listUser",
        component: () => import("../views/admin/ListUser.vue"),
        meta: { title: "ユーザー管理", needLogin: true }
      },
      {
        path: ROUTER_PATH.ADD_USER,
        name: "addUser",
        component: () => import("../views/admin/AddUser.vue"),
        meta: { title: "ユーザー新規", needLogin: true }
      },
      {
        path: ROUTER_PATH.UPDATE_USER,
        name: "updateUser",
        component: () => import("../views/admin/UpdateUser.vue"),
        meta: { title: "ユーザー更新", needLogin: true }
      },
      {
        path: ROUTER_PATH.ADMIN_GROUP,
        name: "listRole",
        component: () => import("../views/admin/ListRole.vue"),
        meta: { title: "グループ管理", needLogin: true }
      },
      {
        path: ROUTER_PATH.ADD_GROUP,
        name: "addRole",
        component: () => import("../views/admin/AddRole.vue"),
        meta: { title: "グループ新規", needLogin: true }
      },
      {
        path: ROUTER_PATH.UPDATE_GROUP,
        name: "updateRole",
        component: () => import("../views/admin/UpdateRole.vue"),
        meta: { title: "グループ更新", needLogin: true }
      },
      {
        path: ROUTER_PATH.KBN_MASTER,
        name: "listMaster",
        component: () => import("../views/master/ListMaster.vue"),
        meta: { title: "マスター管理", needLogin: true }
      },
      {
        path: ROUTER_PATH.ADD_MASTER,
        name: "addMaster",
        component: () => import("../views/master/AddMaster.vue"),
        meta: { title: "マスター新規", needLogin: true }
      },
      {
        path: ROUTER_PATH.UPDATE_MASTER,
        name: "updateMaster",
        component: () => import("../views/master/UpdateMaster.vue"),
        meta: { title: "マスター更新", needLogin: true }
      },
      {
        path: ROUTER_PATH.SYS_CONFIG,
        name: "sysConfig",
        component: () => import("../views/config/SysConfig.vue"),
        meta: { title: "システム設定", needLogin: true }
      },
      {
        path: ROUTER_PATH.ADMIN_RPA,
        name: "listRpa",
        component: () => import("../views/config/ListRpa.vue"),
        meta: { title: "Rpaサーバー管理", needLogin: true }
      },
      {
        path: ROUTER_PATH.ADD_RPA,
        name: "addRpa",
        component: () => import("../views/config/AddRpa.vue"),
        meta: { title: "Rpaサーバー新規", needLogin: true }
      },
      {
        path: ROUTER_PATH.UPDATE_RPA,
        name: "updateRpa",
        component: () => import("../views/config/UpdateRpa.vue"),
        meta: { title: "Rpaサーバー更新", needLogin: true }
      },
    ]
  },
  {
    path: "/error",
    name: "errorPage",
    component: () => import("../views/layout"),
    children: [
      {
        path: "/404",
        name: "Page404",
        component: () => import("../views/errorPage/404.vue"),
        meta: { title: "Page 404" }
      },
      {
        path: "/401",
        name: "Page401",
        component: () => import("../views/errorPage/401.vue"),
        meta: { title: "Page 401" }
      }
    ]
  },
  {
    path: "*",
    redirect: "/404"
  }
];

const router = new VueRouter({
  routes
});

export default router;
