export const LOCAL_STORAGE_KEY = {
  RPA_USER_ID: "RPA_USER_ID",
  RPA_TOKEN: "RPA_TOKEN",
  RPA_TOKEN_EXPIRE: "RPA_TOKEN_EXPIRE"
};

export const ROUTER_PATH={
    HOME:'/robot/myRobot',
    LOGIN:'/account/login',
    LOGIN_OUT:"/account/loginOut",
    ACCOUNT_INFO:'/account/accountInfo',
    ADMIN_USER:"/admin/listUser",
    ADD_USER:"/admin/addUser",
    UPDATE_USER:"/admin/updateUser",
    ADMIN_GROUP:"/admin/listRole",
    ADD_GROUP:"/admin/addRole",
    UPDATE_GROUP:"/admin/updateRole",
    APPLY_CENTER:"/ac",
    ADD_CUSTOMER:"/pp/addCustomer",
    KBN_MASTER:"/master/listMaster",
    ADD_MASTER:"/master/addMaster",
    UPDATE_MASTER:"/master/updateMaster",
    SYS_CONFIG: "/config/sysConfig",
    ADMIN_RPA:"/config/listRpa",
    ADD_RPA:"/config/addRpa",
    UPDATE_RPA:"/config/updateRpa",
}