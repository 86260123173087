<template>
  <div id="app">
    <router-view></router-view>
    <loading-mask v-show="loading" />
  </div>
</template>

<script>
import LoadingMask from "@/components/common/LoadingMask.vue";
import { mapState } from "vuex";
export default {
  name: "App",
  components: { LoadingMask },
  computed: {
    ...mapState(["loading"])
  }
};
</script>

<style>
html,
body,
#app {
  height: 100%;
  background-color: #e9ecef;
  font-family:"ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro",Osaka, "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}
.dash-box{
  width: 90%;
  height: 60px;
  border: 1px solid #e9ecef;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
}
.dash-title{
  font-size: 11px;
  color: #909399;
}
.dash-value{
  font-size: 24px;
  font-weight: 500;
}
.dash-num-orange{
  color: #E6A23C;
}
.dash-num-blue{
  color: #409EFF;
}
.dash-num-green{
  color: #67C23A;
}
.btns .el-button {
  margin-right: 5px;
  margin-bottom: 1px;
  margin: 5px !important;
}
</style>
