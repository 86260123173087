
export default {
  accountInfo: {
    id: '',
    name: "",
    groupId: "",
    groupName: "",    
    role: "",
    showMenuFlg: 1
  }
};
